import React from "react"
import { NavListItem } from "./style"
import { Link } from "gatsby"

const NavBar = ({ label, onClick}) => {
  return (
    <NavListItem>
      <button onClick={onClick}>
        <span>{label}</span>
      </button>
    </NavListItem>
  )
}

export default NavBar
