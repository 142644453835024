import React, { useState, useContext } from "react"
import { LogoWrapper, NavBarContainer, NavWrapper, Nav, NavList } from "./style"
import NavItem from "./navItem"
import Hamburger from "./hamburger"
import { PopUpContext } from "context/PopUpContext"
import { ReactComponent as Logo } from "images/no-limit-logo.svg"

const NavBar = () => {
  const [isNavShown, setNavShown] = useState(false)
  const { setPopUpOpen } = useContext(PopUpContext)

  const toggleShowNav = () => {
    setNavShown(!isNavShown)
  }

  const scrollTo = id => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" })
  }

  return (
    <NavBarContainer>
      <LogoWrapper to="/">
        <Logo />
      </LogoWrapper>
      <NavWrapper>
        <Nav isNavShown={isNavShown}>
          <NavList>
            <NavItem label="About" onClick={() => scrollTo("about-module")} />
            <NavItem
              label="Services"
              onClick={() => scrollTo("services-module")}
            />
            <NavItem
              label="Pricing"
              onClick={() => scrollTo("pricing-module")}
            />
            <NavItem
              label="Testimonials"
              onClick={() => scrollTo("testimonials-module")}
            />
            <NavItem label="Contact" onClick={() => setPopUpOpen(true)} />
          </NavList>
        </Nav>

        <Hamburger isNavShown={isNavShown} toggleShowNav={toggleShowNav} />
      </NavWrapper>
    </NavBarContainer>
  )
}

export default NavBar
