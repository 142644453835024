import React from "react"

import { NavBar } from "../NavBar"
import { HeaderContainer } from "./style"
import { MainColumn } from "components/MainColumn"

const Header = () => {
  return (
    <HeaderContainer>
      <MainColumn>
        <NavBar />
      </MainColumn>
    </HeaderContainer>
  )
}

export default Header
